@import '../../../base/assets/scss/_base/config';

.favorites-dialog {
  background-color: $white;
  box-shadow: 0 0 35px 5px lighten($gray-base, 33.5%);
  font-size: 14px;
  left: 100px;
  max-width: 600px;
  padding: 50px;
  position: absolute;
  top: 100px;
  z-index: 99999;

  &.favorites-delete-dialog {
    width: 500px;
  }

  .favorites-dialog-title {
    border-bottom: 1px dotted lighten($gray-base, 46.7%);
    font-size: floor((14px * 2.15));
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1em;
    margin-bottom: 0;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  a {
    cursor: pointer;
  }

  .favorites-dialog-close-button:not(.invisible) {
    color: $gray-base;
    float: right;
    margin-top: -20px;

    &:hover {
      color: $fav-pink;
    }
  }

  .favorites-header {
    background-color: $gray-dark;
    color: $white;
    height: 25px;
    margin: -50px 0 40px -50px;
    padding: 4px 50px;
    width: 100%;

    .navicon-fav-heart-banner {
      color: $fav-pink;
      display: block;
      float: left;
      font-size: 42px;
      height: 45px;
      margin-right: 5px;
      margin-top: -4px;
      top: 0;
      width: 40px;
    }

    .favorites-header-title {
      background: 0;
      color: $white;
      display: block;
      height: 25px;
      margin-left: 42px;
      margin-top: 0;
      text-transform: uppercase;
      width: 100px;
    }

    .favorites-dialog-close-button {
      color: $gray-base;

      &:hover {
        color: $fav-pink;
      }
    }

    .favorites-header-login {
      float: right;
      font-size: ceil((14px * 0.85));
      margin-right: 22px;
      margin-top: -20px;
      text-align: right;
    }
  }

  form {
    .favorites-dialog-content {
      border-bottom: 1px dotted #777;
      margin-bottom: 25px;
      max-width: 500px;
      padding: 35px 0;
      word-wrap: break-word;

      label {
        display: inline-block;
        float: left;
        font-size: 14px;
        font-weight: bold;
        margin-top: 12px;
        text-transform: uppercase;
        width: 110px;
      }

      .favorites-input {
        border: 1px solid #d3d3d3;
        box-sizing: content-box;
        display: inline-block;
        float: left;
        font-size: 14px;
        margin: 0;
        margin-bottom: 30px;
        padding: 10px 15px;
        width: 357px;
      }
    }

    .favorites-dialog-message {
      color: $pink;
      font-size: 12px;
      line-height: 20px;
      margin-left: 110px;
      margin-top: -15px;
    }

    .favorites-dialog-buttons {
      text-align: right;
      width: 100%;

      a {
        background-color: $pink;
        color: $white;
        display: inline-block;
        font-weight: bolder;
        margin-left: 8px;
        padding: 9px 15px;
        text-align: center;
        text-decoration: none;

        &:first-child,
        &.favorites-first {
          margin: 0;
        }
      }

      .favorites-dialog-close-button {
        float: left;
      }

      .favorites-delete-button {
        background: linear-gradient(to bottom, $white 0%, #e6e6e6 100%);
        border: 1px solid $gray-border;
        color: black;
        float: left;
      }
    }

    .favorites-dialog-logo img {
      color: $gray-lighter;
      display: inline-block;
      float: left;
      margin-top: -20px;
      width: 50px;
    }

    .selector-container {
      display: inline-block;
      font-size: 14px;
      width: 387px;

      input.selector-search-input {
        background-color: $white;
        border: 0;
        box-sizing: content-box;
        display: block;
        font-size: 15px;
        font-weight: bold;
        margin: 0;
        padding: 10px 15px;
        width: 340px;
      }

      .selector-menu-button {
        border: 1px solid #ccc;
        box-sizing: initial;
        color: black;
        cursor: pointer;
        display: inline-block;
        float: right;
        font-size: 0;
        font-weight: bold;
        height: 34px;
        margin-top: -38px;
        overflow: hidden;
        padding: 1px 0;
        position: relative;
        text-align: center;
        width: 385px;

        &::before {
          content: url('https://s.yimg.com/cv/apiv2/default/20200701/down.svg');
          float: right;
          font-size: 16px;
          margin: 10px;
        }

        &:hover {
          color: $fav-pink;
        }
      }

      .selector-menu {
        border: 1px solid #ccc;
        border-top: 0;
        box-sizing: border-box;
        display: none;
        width: 100%;

        .selector-text-input {
          background-color: $white;
          border: 0;
          box-sizing: content-box;
          display: block;
          font-size: 15px;
          font-weight: 300;
          margin: 0;
          padding: 13px 15px;
        }

        .selector-menu-items {
          background-color: $white;
          display: block;
          list-style-type: none;
          margin: 0;
          max-height: 87px;
          min-height: 16px;
          overflow-x: hidden;
          overflow-y: auto;
          padding: 5px 0;
          width: 100%;

          .subempty {
            color: $gray-light;
            padding: 4px 15px;
          }

          .selector-menu-item {
            cursor: pointer;
            display: block;
            list-style-image: none;
            margin: 0;
            padding: 4px 15px;
            text-align: left;

            a {
              color: $gray-base;
              text-decoration: none;
            }
          }
        }

        .selector-toolbar {
          background-color: $gray-lighter;
          border-top: 1px solid #ccc;
          width: 100%;

          /* stylelint-disable function-linear-gradient-no-nonstandard-direction */
          a {
            background: linear-gradient(to bottom, $white 0%, #e6e6e6 100%); /* W3C */
            background:
              -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, $white),
                color-stop(100%, #e6e6e6)
              ); /* Chrome,Safari4+ */

            background: $white; /* Old browsers */
            background-color: $gray-lighter;
            border-right: 1px solid #ccc;
            box-sizing: border-box;
            color: $gray-base;
            cursor: pointer;
            display: inline-block;
            height: 37px;
            padding: 10px 8px;

            &.selector-add-button {
              border: none;
              color: $gray-dark;
              font-size: 12px;
              font-weight: 400;
              padding: 12px 8px;
              text-decoration: none;

              &.disabled {
                color: $gray-light;

                &:hover {
                  color: $gray-light;
                }
              }
            }
          }

          &.selector-top-level {
            .selector-top-button,
            .selector-up-button {
              box-sizing: border-box;
              color: $gray-light;
              cursor: default;
              height: 37px;
              opacity: 0.5;
            }
          }

          .selector-add-input {
            background-color: $white;
            border-right: 1px solid #ccc;
            display: inline-block;
            font-size: 14px;
            margin: 0;
            width: 249px;
          }
        }
      }
    }
  }

  .addicon {
    &.lft_bdr {
      a {
        border-right: 1px dotted #b7b7b7;
      }
    }

    a {
      float: left;
      padding-top: 20px;
      text-align: center;
      width: 50%;

      &:hover {
        color: $pink;
        text-decoration: none;
      }

      span {
        margin: 0 auto;
      }
    }

    .favorites-icon-label {
      color: $gray-dark;
      display: block;
      font-family: BebasNeueRegular, 'Arial Narrow', Helvetica, sans-serif;
      font-size: 1.8em;
      text-transform: uppercase;
    }

    .favorites-icon {
      color: $gray-base;
      display: block;
      font-size: 96px;
      margin: 0 auto 20px;

      &:hover {
        color: $fav-pink;
      }
    }
  }

  &.favorites-both {
    max-width: 600px !important;
    padding-top: 40px;
    width: auto;

    .favorites-dialog-title-wrap {
      border-bottom: 1px dotted #777;
      display: flex;
      justify-content: space-between;

      .favorites-dialog-title {
        border: none;
      }

      .favorites-dialog-close-button {
        float: unset;
        margin: 0;
      }
    }

    .favorites-dialog-content {
      display: flex;
      justify-content: space-around;

      .addicon {
        padding-top: 40px;
        text-align: center;
        width: 50%;

        &.lft_bdr {
          border-right: 1px dotted #b7b7b7;

          a {
            border: none;
          }

          svg {
            position: relative;
            top: 12px;
          }
        }

        a {
          float: unset;
          text-decoration: none;
        }

        .favorites-icon-label {
          margin-top: 40px;
          width: 250px;
        }
      }

      a {
        padding-top: 20px;
        text-align: center;
        width: 50%;

        &:hover {
          color: $pink;
          text-decoration: none;
        }
      }
    }
  }

  &.favorites-saved-dialog {
    .fav-chkbox {
      float: left;
      margin-right: 10px;
      margin-top: 12px;
    }

    .favorites-input-checkbox-label {
      float: left;
      font-size: 15.6px;
      margin-top: 9px;
    }

    .favorites-confirmsave-button {
      float: right;
      width: 200px;
    }
  }
}

.favorites-controller {
  font-family: var(--font-family-base);
  overflow-x: hidden;

  .colorbox {
    background: #fff;
    border: 1px solid #F0F3F5;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .fav-ovl {
      background: url('https://s.yimg.com/cv/apiv2/default/20200701/favorites_illustration3.png');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 925px 480px;

      .fav-ovl-top {
        height: 400px;
        padding-top: 40px;

        h2 {
          color: $gray-darker;
          font-size: 40px;
          font-weight: 300;
          margin: 0;
          padding-left: 100px;
          padding-top: 35px;
          text-align: left;
        }

        .fav-top-section {
          margin-left: 40px;

          .overlay-top-text {
            color: $gray-dark;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            margin-top: 20px;
            text-align: center;
            width: 320px;
          }
          .favbtn {
            margin-left: 31px;
            margin-top: 22px;
            width: 250px;

            a{
              text-decoration: none;
              color:#fff;
            }
          }
        }
      }

      .fav-ovl-mid {
        display: flex;
        width: 100%;

        .middle-right {
          font-size: 14px;
          margin-left: 20px;
          margin-top: 30px;
          padding-bottom: 10px;
        }

        .middle-left {
          font-family: 'BebasNeueRegular', 'Arial Narrow', Helvetica, sans-serif;
          font-size: 25px;
          font-weight: bold;
          margin: 10px;
          margin-left: 40px;
          margin-top: 25px;
          width: 300px;

          .pnk {
            color: $pink;
            font-style: normal;
          }
        }
      }
    }

    .fav-ovl-btm {
      clear: both;
      color: $gray-dark;
      padding-top: 15px;
      position: relative;

      .fav-ovl-btmrgt {
        font-size: 14px;
        line-height: 28px;
        margin: 26px 0 30px 40px;
        margin: auto;
        margin: 60px 100px;
        padding-right: 0;
        position: relative;
        text-align: center;
        text-align: left;
        width: 381px;

        .bml-title {
          font-size: 22px;
          font-weight: 400;
          margin-left: 5px;
          padding-bottom: 20px;
        }

        .bml-text {
          margin-left: 5px;
          width: 275px;
        }

        .bkicon {
          display: flex;
          margin-top: 20px;

          a {
            color: $blue;
          }

          .bookmarkleticon {
            margin-left: 0;
          }

          .desc {
            display: block;
            font-size: 12px;
            line-height: 18px;
            margin: 10px 0 0 5px;
            padding-top: 6px;
            width: 200px;
          }
        }
      }
    }
  }

  .clearfix::after {
    clear: both;
    content: ' ';
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }

  .favorites-container {
    margin-bottom: 50px;
    position: relative;
    width: 95%;

    .favorites-notice {
      background: #c3e7e7;
      border-bottom: 1px solid #b5d0d1;
      border-top: 1px solid #d3f2ef;
      box-shadow: 0 4px 8px $gray-lighter;
      display: none;
      min-height: 12px;
      padding: 6px 0;
      position: relative;
      text-align: center;

      .flg {
        display: inline-block;
      }

      p {
        margin: 0;
      }

      .favorites-notice-close-button {
        color: black;
        display: inline;
        font-weight: bold;
        padding-left: 10px;
        position: relative;
        text-decoration: none;
        top: 1px;

        .fav-close-icon {
          height: 12px;
          width: 12px;
        }

        .navicon-fav-heart {
          position: relative;
          top: 2px;
        }

        &:hover {
          color: black;
          text-decoration: underline;
        }
      }

      .navicon-fav-heart {
        margin-right: 5px;
        position: relative;
        top: 2px;

        .fav-heart {
          width: 14px;
        }
      }

      .favorites-notice-pinkheart {
        color: $pink;
      }

      &.notice-show {
        display: flex;
        justify-content: center;
        background: none;
        border: none;
        box-shadow: none;
      }

      .favorites-signin-link {
        color: $gray-base;
        font-weight: bold;
        text-decoration: none;

        &:hover {
          color: $blue;
        }
      }
    }

    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }

    a {
      cursor: pointer;
    }

    .active-tab,
    .active-tab:hover {
      background: $pink;
    }

    .favorites-header {
      display: flex;
      font-size: 24px;
      justify-content: space-between;
      text-align: left !important;

      .favorites-help-button {
        color: $gray-base;

        &:hover {
          color: $fav-pink;
        }

        .help-icon {
          height: 15px;
          vertical-align: baseline;
          width: 15px;
        }
      }
    }

    .favorites-tools-header {
      background: $gray-light;

      .favorites-nav-header {
        background: $gray-light;
        float: left;
        padding-right: 1px;
        position: relative;
        width: 164px;

        a {
          color: $white;
        }

        &.navbtn {
          font-size: 13px;
          height: 44px;
          text-align: left;

          a {
            color: $white;
            font-weight: normal;
            margin-left: 10px;
            padding: 15px 0 13px;

            &.favorites-nav-button {
              display: inline-block;
              text-decoration: none;
              width: 95%;
            }
          }

          &.active-tab {
            background: $fav-pink;
          }

          &:hover {
            background: $gray-light;
            text-decoration: none;
          }
        }
      }
    }

    .favorites-tools {
      border-bottom: 1px solid $gray-border;
      display: flex;

      a {
        color: $gray-base;
        display: inline-block;
        font-weight: bold;
        padding: 14px 0;
        padding: 14px 0 14px 10px;
        text-decoration: none;

        &:hover {
          color: $fav-pink;
          text-decoration: none;
        }
      }

      .add-icon {
        height: 14px;
        width: 14px;
      }

      .tool-nav {
        background: none;
        display: flex;
        height: 44px;
        width: 123px;

        .favesroot {
          padding-left: 25px;
        }

        &.favorites-tools-addfdr {
          border-right: 1px solid $gray-lighter;
        }

        &.fav-addtool {
          cursor: pointer;
          width: 41px;
        }
      }

      .tool-nav-left,
      .favorites-tools-addfdr {
        background: $dropdown-border-gray;
      }

      .favorites-content-header {
        background: $white;
        display: flex;
        height: 44px;
        justify-content: space-between;
        width: 75%;

        .favorites-breadcrumb {
          color: $gray-light;
          display: flex;

          a {
            text-decoration: none;
          }

          span {
            display: inline-block;
            margin-left: 67px;
          }
        }

        .favorites-sort {
          margin-right: 45px;
          margin-top: 10px;
          width: 90px;

          .favorites-sort-button {
            border: 1px solid $gray-border;
            float: left;
            font-size: 11.4px;
            padding: 5px;
            position: absolute;
            text-transform: uppercase;

            &.navicon-down {
              &::after {
                content: url('https://s.yimg.com/cv/apiv2/default/20200701/down.svg');
                padding-left: 10px;
                position: relative;
                top: 2px;
              }
            }
          }

          select {
            appearance: none;
            cursor: pointer;
            filter: alpha(opacity=0);
            float: left;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 0.8em;
            min-width: 89px;
            opacity: 0;
            padding: 5px;
            position: absolute;
            text-transform: uppercase;
            width: 90px;
            z-index: 10;
          }
        }
      }
    }

    .favorites-content-container {
      border-bottom: 2px solid $gray-base;
      display: flex;
      min-height: 620px;
      overflow: hidden;

      .navicon-close {
        color: $white;
        float: right;
        margin: 2px 3px 1px 2px;
        width: 9px;

        &:hover {
          color: black;
        }
      }

      .favorites-nav {
        background-color: $gray-background;
        border-right: 1px solid $gray-border;
        box-shadow: inset -4px -4px 8px -2px $gray-border;
        min-height: 10px;
        width: 164px;

        .fTipFdr {
          background-color: $pink;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          color: $white;
          display: none;
          padding: 10px;
          text-align: left;

          a:not(.navicon-close) {
            color: $blue;
            text-decoration: none;

            &:hover {
              color: $off-white;
            }
          }
        }

        .favorites-nav-folders {
          position: relative;
          text-align: left;
          top: -15px;

          .folder {
            list-style-type: none;

            a {
              text-decoration: none;
            }
          }

          .subfdr {
            background: $white;
            margin-top: -5px;
            padding-top: 13px;

            a[name='select'] {
              padding-left: 10px;
            }

            /* stylelint-disable selector-pseudo-class-no-unknown */
            &:parent {
              background-color: #aaa;
            }
          }

          ul {
            margin-bottom: 5px;
            padding: 0;

            li {
              overflow: hidden;
              padding: 10px;
              position: relative;
              text-overflow: ellipsis;
              white-space: nowrap;

              a {
                color: $gray-dark;
              }

              &:hover {
                a {
                  color: $pink;
                }

                .favorites-item-toolbar {
                  display: block;
                }
              }

              &:first-child {
                margin-top: 5px;
              }

              &.favorites-hover a {
                color: $pink;
              }

              &.favorites-first {
                margin-top: 5px;
              }

              &.favorites-current {
                background-color: $white;
                border-bottom: 1px dotted #aaa;
                border-right: 1px solid $white;
                color: $pink;
                font-weight: bold;
                margin-bottom: 5px;
                margin-right: -1px;
                padding-top: 15px;

                a:first-child {
                  color: $fav-pink;
                  font-weight: bold;
                }

                &:first-child {
                  border-top: none;
                  margin-top: 0;
                }

                &.favorites-first {
                  border-top: none;
                  margin-top: 0;
                }
              }

              .favorites-item-toolbar {
                display: none;
                float: right;

                .fav-edit-icon {
                  height: 15px;
                  width: 15px;
                }

                .add-icon {
                  height: 13px;
                  width: 15px;
                }
              }
            }
          }
        }
      }

      &.favorites-bookmarks-view .favorites-content .favorites-item .favorites-item-icon {
        display: none;
      }
    }

    .favorites-content {
      margin-bottom: 30px;
      width: 75%;

      .fTipItem {
        background-color: $pink;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        color: $white;
        display: none;
        padding: 10px;
        padding-left: 20px;
        text-align: left;

        a:not(.navicon-close) {
          color: $blue;
          text-decoration: none;

          &:hover {
            color: $off-white;
          }
        }

        .navicon-close {
          color: $white;
          float: right;
          font-size: 10px;

          &:hover {
            color: black;
          }
        }
      }

      .folder-item-list {
        display: none;

        &.folder-list-current {
          display: block;
        }
      }

      .favorites-item {
        border-bottom: 1px dotted #b7b7b7;
        margin-left: 20px;
        padding: 14px 5px;
        position: relative;
        text-align: left;
        vertical-align: middle;
        zoom: 1;

        &.favorites-first {
          border-top: none;
        }

        &:first-child {
          border-top: none;
        }

        &.favorites-last {
          border-bottom: none;
          margin-bottom: 51px;
        }

        &:hover,
        &.favorites-hover {
          box-shadow: 4px 4px 8px $gray-border;

          a {
            color: $gray-base;
            padding: 2px;
            text-decoration: none;

            &:hover {
              color: $pink;
            }
          }

          h3 {
            color: $pink;
          }

          .favorites-item-domain {
            color: #ff91ba;
          }

          .favorites-item-date {
            display: none;
          }

          .favorites-item-toolbar {
            display: block;
          }
        }

        &.favorites-empty {
          border-bottom: none;
          text-align: center;

          &:hover {
            box-shadow: none;
          }

          &.favorites-hover {
            box-shadow: none;
          }

          .favorites-empty-message {
            color: $dolphin;
            font-size: 20px;
            padding: 19px 0 0;
            width: 225px;
            font-weight: 500;

            &.hide {
              display: none;
            }
          }
        }

        a {
          color: $gray-base;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        .favorites-item-icon {
          //empty image?
          display: inline-block;
          height: 40px;
          margin-right: 14px;
          vertical-align: middle;
          width: 40px;
        }

        .favorites-item-content {
          display: inline-block;
          max-width: calc(100% - 60px);
          min-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;

          h3 {
            display: inline;
            font-size: 1.2em;
            font-weight: normal;
            margin-bottom: 3px;
            white-space: nowrap;
          }

          .favorites-item-domain {
            clear: left;
            color: $gray-light;
            display: block;
            font-size: 0.9em;
            min-height: 12px;
          }
        }

        .favorites-item-date {
          display: inline-block;
          float: right;
          font-size: 0.9em;
          margin-top: 14px;
          vertical-align: middle;
        }

        .favorites-item-toolbar {
          display: none;
          margin-right: 5px;
          margin-top: -24px;
          position: absolute;
          right: 10px;
          vertical-align: middle;

          .fav-edit-icon {
            height: 15px;
            width: 15px;
          }

          .fav-delete-icon {
            height: 13px;
            width: 15px;
          }
        }
      }
    }

    &.auth-false {
      .favorites-tools {
        .fav-addtool {
          cursor: auto;

          a {
            height: 28px;
          }

          &.auth-false {
            cursor: auto;
          }
        }

        .favorites-nav-root {
          cursor: auto;

          &:hover {
            color: $gray-base;
          }
        }

        .favorites-content-container {
          min-height: 836px;
        }
      }
    }

    .favorites-page-nav {
      border-top: 1px solid $gray-lighter;
      bottom: 17px;
      color: $gray-light;
      display: flex;
      left: 162px;
      padding: 17px 5px;
      position: absolute;
      text-align: center;
      width: 75%;

      .favorites-nav-count {
        font-size: 0.9em;
        margin-left: 18px;
        position: absolute;
      }
    }
  }

  @media screen and (max-width: 980px) {
    .favorites-container {
      .favorites-tools .favorites-content-header {
        width: 64%;
      }

      .favorites-content-container .favorites-content {
        width: 64%;

        .favorites-page-nav {
          width: 64%;
        }
      }
    }
  }

  @media screen and (min-width: 980px) and (max-width: 1100px) {
    .favorites-container {
      .favorites-tools .favorites-content-header {
        width: 70%;
      }

      .favorites-content-container .favorites-content {
        width: 70%;

        .favorites-page-nav {
          width: 70%;
        }
      }
    }
  }

  .noOvr {
    overflow: hidden;
  }

  .favorites-dialog.favorites-bookmarklet {
    border: 0;
    box-shadow: none;
    font-family: var(--font-family-base);
    font-size: 14px;
    left: 0;
    line-height: normal;
    max-width: 500px;
    position: absolute;
    top: 0;

    .favorites-dialog-close-button:not(.invisible) {
      color: $white;
    }

    .favorites-dialog-message {
      margin-top: 0;
    }
  }
  .favbtn {
    background-color: $blue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:15px;
    border-radius: 40px;
    margin-top: 90px;
    font-size: 16px;
    font-weight: 700;
    width: 303px;
    a{
      text-decoration: none;
      color:#fff;
    }
  }
  .get-started-img{
    margin-left: 5px;
    vertical-align: middle;
  }
}
